.progress { 
    background-color: rgb(178, 178, 178);
    font-size: 0.8rem;
    font-weight: bold;
}

.tooltip {
    font-size: 0.8rem;
    font-weight: bold;
}

.chart-modal {
    width: 90%;
    max-width: none !important;
}