.table {
    table-layout: fixed;
}

.table td, .table.th {
    vertical-align: middle
}

.button-text {
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: inline-block;
    width: calc(100% - 0.25rem);
}

.info-modal {
    width: 90%;
    max-width: none !important;
}

